import dmshttp from "@/utils/dmsrequest";
import http from '@/utils/request'
// 查看商品列表
export function getProductList(data) {
    return dmshttp({
        url: 'findInventoryList',
        data
    })
}

export function findProductListLikeCode(params) {
    return http({
        url: '/product/getDefevtivePro.nd',
        params
    })
}

//获取零售价格
export function getRetailPrice(data) {
    return http({
      url: '/product/retailPrice.nd',
      method: 'post',
      data
    })
  }