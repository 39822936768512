// import tableUtil from '@/components/global/TableModal/util' //组件
import { getProductList, queryOfficeManager, queryServiceDirector, queryWarehouse } from "./api";
import Step from "@/components/global/Step/Step.vue";
import ProductList from "../components/ProductList.vue";
import ProductModal from "../components/ProductModal.vue";
import { getInitInfor, getMatklByOrg, getshAddress } from './api'
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import { mapState } from "vuex";
import { getPrincipal, getPrincipalDetail } from "@/views/distributionStores/addDistributionStore/api";


export default {
  name: "Home",
  components: {
    Step,
    ProductList,
    ProductModal
  },

  data() {
    return {
      BussiseGroup: [], // 商家数组
      shopId: '', // 商家id
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入退货信息", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/hisenseReturn/hisenseRrturnInput",
          name: "",
          title: "发起残次退"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      pageLoadFlagaa: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "retail",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: 'count'
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      xsmdId: "",
      isEnableOrNot: true,

      //  my
      shopName: '',
      phoneCon: '',
      contacts: '',
      MaterialGroup: [],
      wlz: '',  // 物料组名称
      wlzid: '', // 物料组id
      orgzzId: '', // 销售组织id
      adressName: '', // 地址
      addressId: '', // 地址id
      adressGroup: [], // 地址集合
      transName: '', // 拉货地址transName
      trans: '', // 拉货id
      file: [],
      showUpload: false,
      showTip:false,
      recevingWareHouse:[],// 仓库
      WhouseId:'',// 收货仓库id
      jlList:[],//经理list
      zrList:[],// 主任list
      jlId: undefined,// 经理id
      jlName: '',// 经理name
      zrId: undefined,// 主任id
      zrName: '',// 主任name

    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    }),


  },
  watch:{
    productList: {
      handler(newValue, oldValue) {
        this.$set(this.productList)
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    this.getinfor()


  },
  methods: {
    // 查询办事处经理
    OfficManagerInput(e) {
      this.jlList = []
      let data = {
          orgId: this.receiveID,//组织
          matklId:this.wlzid,//物料组
          addressId:this.shopId,//退货地址
          search:e
      }
      queryOfficeManager(data).then((res) => {
        if (res.data.code == 0 && res.data.data.length > 0) {
          this.jlList =  this.getNewObjc(res.data.data[0])
        }
      });
    },
    // 办事处经理人改变
    OfficManagerInputChange(value,e) {
      this.jlId = value;
      // this.jlName = this.jlList[e].name;
    },

    // 查询主任
    directorInput(e) {
      let data = {
        orgId: this.receiveID,//组织
        matklId:this.wlzid,//物料组
        addressId:this.shopId,//退货地址
        search:e
      }
      queryServiceDirector(data).then((res) => {
        if (res.data.code == 0 && res.data.data.length > 0) {
          this.zrList =  this.getNewObjc(res.data.data[0])
        }
      });
    },
    // 主任改变
    directorChange(value,e) {
      this.zrId = value;
      // this.zrName = this.zrList[e].name;
    },
     // 获取货仓库 接口还未发版  没法测试
   async getWareHouse(id){
     this.recevingWareHouse = []
      let data ={
        orgId:id
      }
      queryWarehouse(data).then(res=>{
        if(res.data.code== 0 && res.data.data.length >0) {
          this.recevingWareHouse =  this.getNewObjc(res.data.data[0])
          this.WhouseId = this.recevingWareHouse.length>0?String(this.recevingWareHouse[0].id):''
        }
      })
    },
    // 仓库数据处理
    // 数据重购
    getNewObjc(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id:String(obj[key]) ,
          name:key ,
        });
      }
      return newArr
    },

    handleChangeWhouse(value, e){
      this.WhouseId = String(value)
      this.$forceUpdate()
    },


     // 更改地址
     handleChangeBussise(value, e) {
      this.shopId = e.key
      this.shopName = value
       if(this.isFenxiaoOrder.indexOf("17451") !== -1 &&  this.shopName.indexOf("代理") !== -1) {
         this.showTip = true;
       } else {
         this.showTip = false;
       }
    },

    //  获取下拉信息
    getinfor() {
      getInitInfor().then(res => {
        this.BussiseGroup = res.data.custList
        this.shopName = res.data.custList[0].fullName
        this.shopId = res.data.custList[0].mdmCode
        // 判断是否是分销商
        if(this.isFenxiaoOrder.indexOf("17451") !== -1 &&  this.shopName.indexOf("代理") !== -1) {
          this.showTip = true;
        } else {
          this.showTip = false;
        }
        // this.shopName = res.data.defectiveProductModel.customerName
        this.phoneCon = res.data.defectiveProductModel.proposerTel
        this.contacts = res.data.defectiveProductModel.proposerName
        // 销售组织
        // this.orderDis = res.data.orgList
        // 筛选出厨电组织
        this.orderDis =res.data.orgList.length > 0 ? res.data.orgList.filter(it=>it.organizationName.indexOf('厨电') != -1) : []
        // 拉货方式 暂时截取前两个
        this.deliveryOptions = res.data.edtList.length >= 3 ? res.data.edtList.slice(0,-1):res.data.edtList
        this.trans = this.deliveryOptions[0].propertyValue;
        this.transName = this.deliveryOptions[0].propertyName
      })
    },

    // 选择销售组织后进行物料组织的联动
    handleChangorgId(value, e) {
      this.receive = value;
      this.receiveID = e.key;
      this.receiveAdd = !this.receiveAdd;
      this.zrList = []
      this.jlList = []
      this.zrId = undefined
      this.zrName = ''
      this.jlId = undefined
      this.jlName = ''
      this.getMetarialGroup(e.key);
      this.showUpload = this.receive.indexOf('冰箱') !== -1
      this.orderDis.forEach(item => {
        if (item.organizationCode == this.receiveID) {
          this.orgzzId = String(item.id)
        }
      })
      if( this.receiveID == 6515) {
        this.getWareHouse(this.receiveID)
      }

    },
    // 物料组织获取
    getMetarialGroup(id) {
      this.MaterialGroup = []
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgCode: id
      }
      getMatklByOrg(data).then(res => {
        if (res.data) {
          this.MaterialGroup = this.getNewObj(res.data)
          if (this.MaterialGroup.length > 0) {
            // v-model的物料组名字
            this.wlz = this.MaterialGroup[0].name
            // 物料组id
            this.wlzid = this.MaterialGroup[0].id
            this.getAdress()
          }
        } else {
          this.MaterialGroup = []
        }
      })
    },
    //  根据组织id和物料组id 获取 地址下拉数据
    getAdress() {
      this.adressGrou = []
      let data = {
        orgCode: this.receiveID,
        productModel: this.wlzid
      }
      getshAddress(data).then(res => {
        if (res.data) {
          this.adressGroup = this.getNewObj(res.data)
          if (this.adressGroup.length > 0) {
            this.adressName = this.adressGroup[0].name
            this.addressId = this.adressGroup[0].id
          }
        } else {
          this.adressGroup = []
          this.adressName = ''
          this.addressId = ''
        }
      })
    },
    // 数据重购
    getNewObj(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
        });
      }
      return newArr
    },
    // 更改物料组
    handleChangemarerial(value, e) {
      this.wlzid = e.key
      this.wlz = value
      this.zrList = []
      this.jlList = []
      this.zrId = undefined
      this.zrName = ''
      this.jlId = undefined
      this.jlName = ''
      this.getAdress()
    },
    // 更改地址
    handleChangeAddress(value, e) {
      this.addressId = e.key
      this.adressName = value
      this.zrList = []
      this.jlList = []
      this.zrId = undefined
      this.zrName = ''
      this.jlId = undefined
      this.jlName = ''
    },
    // 更改拉货方式
    onGiveType(e) {
      this.trans = this.deliveryOptions[e.target.value].propertyValue;
      this.transName = this.deliveryOptions[e.target.value].propertyName
    },

    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000066'
        })
        this.$message.info(msg && msg.msgDesc);
        // this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(data).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);
      });
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },
    addProduct(productList) {

      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000067'
          })
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(it => it.productCode);
        productList.forEach(it => {
          if (!models.includes(it.productCode)) {
            it.checked = true;
            it.itemId = "";
            it.bprice = it.retailPrice
            it.message = ''
            it.MachineCode = ''
            it.ProNum = Number(0)
            models.push(it.productCode);
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {
              if (item.productCode == it.productCode) {
                item.ProNum = Number(item.ProNum)
                item.ProNum += 1
                this.$set(this.productList, item, item)
              }
            })
          }
        });
      } else {
        if (productList.length > 0) {
          let pro = productList[0];
          const index = this.productList.findIndex(
            it => it.productCode == this.selRow.productCode
          );
          pro = {
            ...pro,
            checked: true,
            itemId: "",
            bprice: this.productList[index].bprice
          };
          this.$set(this.productList, index, pro);
        }
      }


    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },

    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert("submit!");
        } else {

          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    // 订单提交
    submitOrder() {
      if (!this.receiveID) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.adressName ) {
        this.$message.warning('收货地址不能为空');
        return;
      }
      if (!this.WhouseId && this.receiveID == 6515) {
        this.$message.warning('收货仓库不能为空');
        return;
      }
      if (!this.zrId && this.receiveID == 6515) {
        this.$message.warning('服务主任不能为空');
        return;
      }
      if (!this.jlId && this.receiveID == 6515) {
        this.$message.warning('办事处经理不能为空');
        return;
      }
      const salesOrderItem = this.productList.filter(it => it.checked);
      if (salesOrderItem.length < 1) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000075'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.MachineCode) {
            this.$message.warning(`请检查第${i + 1}个机编码！`);
            return;
          }
        }
      }
      let itenlist = this.productList.map(item => {
        return {
          productModel: this.wlzid,
          materialName: this.wlz,
          productName: item.zzprdmodel,
          organizationCode: this.receiveID,
          productCode: item.productCode,
          organizationName: this.receive,
          machineNum: (item.MachineCode).split('\n'),  // .slice(0,item.MachineCode.length-1)
          remarks: item.message,
          invStatusType:item.invStatusType
        };
      })
      // 把数据拆分重组数据传给后台
      let letitemlist = []
      itenlist.forEach(item => {
        // 如果最后一项为'' 长度减一 
        if (!item.machineNum[item.machineNum.length - 1]) {
          item.machineNum.length = item.machineNum.length - 1
        }
        for (var i = 0; i < item.machineNum.length; i++) {
          letitemlist.push(
            {
              productModel: item.productModel,
              materialName: item.materialName,
              productName: item.productName,
              organizationCode: item.organizationCode,
              productCode: item.productCode,
              organizationName: item.organizationName,
              machineNum: item.machineNum[i],
              remarks: item.remarks,
              invStatusType:item.invStatusType
            }
          )
        }
      })

      let file = []
      if (this.file.length > 0) {
        file = this.file[0].originFileObj
      } else {
        file = ''
      }

      let formData = new FormData()
      formData.append('customerCode', this.shopId);
      formData.append('customerName', this.shopName);
      formData.append('returnAddressId', this.addressId);
      formData.append('returnAdr', this.adressName);
      formData.append('rebackCode', this.trans);
      formData.append('reback', this.transName);
      formData.append('proposerName', this.contacts);
      formData.append('organizationCode', this.receiveID);
      formData.append('proposerTel', this.phoneCon);
      formData.append('warehouseId', this.WhouseId); //收货仓库id
      formData.append('serviceDirectorId', this.zrId?this.zrId:'');// 主任id
      formData.append('officeManagerId',  this.jlId?this.jlId:'');// 仓库id
      formData.append('file1', file);
      formData.append('fromString', JSON.stringify(letitemlist))
      let config = {
        headers: { 'content-type': 'multipart/form-data', },
      }
      // 发送请求
      const BASE_API = publicPath;
      this.isLoading = true;
      axios.post(BASE_API + '/defectiveProduct/submissionInfo.nd', formData, config).then(res => {
        if (res.data.flag == true) {
          this.$router.push({
            path: "/hisenseReturn/success",
            query: {
              b2bOrderCode: res.data.serialCode,
              type: "HXTH",
            }
          });
        } else {
          this.$message.warning(res.data.message)
        }
      })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 下载模板
    downFile() {
      const BASE_API = publicPath;
      window.location.href = BASE_API + "/upload/return_import_template.xls"
    },
    // 导入数据 
    importExcel(fileList) {
      this.pageLoadFlagaa = true
      let formData = new FormData()
      formData.append('materialCode', this.wlz);
      formData.append('materialName', this.wlzid);
      formData.append('organizationCode', this.receiveID);
      formData.append('organizationName', this.receive);
      formData.append('fileNum', '');
      formData.append('file', fileList[0].originFileObj);
      let config = {
        headers: { 'content-type': 'multipart/form-data', },
      }
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + '/defectiveProductFile/uploadFile.nd', formData, config).then(res => {
        let importList = res.data.dpFileList
        let list = []
        importList.forEach(item => {
          if (list !== null && list.length > 0) {
            for (let i = 0; i <= list.length; i++) {
              if (list[i].model === item.productName) {
                list[i].MachineCode = list[i].MachineCode + '\n' + item.machineNum
                if (item.remarks) {
                  list[i].message = item.remarks
                  break
                }
              } else if (list[i].model !== item.productName && i === (list.length - 1)) {
                list.push({
                  message: item.remarks,
                  MachineCode: item.machineNum,
                  materialGroupCode: item.materialCode,
                  organizationName: item.organizationName,
                  model: item.productName,
                  zzprdmodel: item.productName,
                  checked: true,
                  ProNum: list[i].MachineCode.split('\n').length
                })
                break
              }
            }
          } else {
            list.push({
              message: item.remarks,
              MachineCode: item.machineNum,
              materialGroupCode: item.materialCode,
              organizationName: item.organizationName,
              model: item.productName,
              zzprdmodel: item.productName,
              checked: true,
            })
          }
        })
        // 查询商品详情
        list.forEach(proItem => {
          let data = {
            cisCode: this.$store.state.user.userInfo.customer.cisCode,
            terms: {
              model: proItem.model,
              warehouseId: this.receiveFHID,
              bigQtyMin: 0,
              orgId:  this.orgzzId,
            },
            page: {
              pageNo: 1,
              pageSize: 100
            }
          }
          getProductList(data).then(res => {
            var ck = JSON.parse(res.data)
            if (ck.data.length == 0) {
              this.$message.warning(proItem.model + '暂无此型号数据，请重新导入', 5)
            } else {
              for (let i = 0; i <= ck.data.length; i++) {
                if (ck.data[i]) {
                  if (ck.data[i].model == proItem.model || ck.data[i].zzprdmodel === proItem.model) {
                    proItem.colour = ck.data[i].colour,
                      proItem.invStatusName = ck.data[i].invStatusName,
                      proItem.invStatusTypeName = ck.data[i].invStatusTypeName,
                      proItem.brandName = ck.data[i].brandName,
                      proItem.volume = ck.data[i].volume
                    proItem.materialGroupCode = Number(ck.data[i].materialGroupCode)
                    proItem.productCode = ck.data[i].productCode
                    proItem.ProNum =  Number(0)
                    // this.productList.push(proItem)
                    const models = this.productList.map(it => it.productCode);
                    // 如果没有想同型号的的数据 就push一条
                    if(models.includes(proItem.productCode)) {
                      // 如果有的话  就向有的数据中新增一条
                      this.productList.forEach(item => {
                        if (item.productCode == proItem.productCode) {
                          item.ProNum += 1
                          // this.productList.push(proItem)
                          this.$forceUpdate()
                        }
                      })
                      return
                    }
                    if(!models.includes(proItem.productCode)){
                      proItem.ProNum =  Number(1)
                      this.productList.push((JSON.parse(JSON.stringify(proItem))))
                      this.$forceUpdate()
                    }
                    // this.$set(this.productList);
                    break
                  }
                }
              }
            }

            // 根据机编码获取数量
            proItem.ProNum = proItem.MachineCode.split('\n').length
          })
        })
        this.pageLoadFlagaa = false
      })
    },
    fileUpload(fileList1) {
      this.file = fileList1
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }
    },
    goCar() {
      this.$router.push("/channelOrder/orderList?type=retail");
    },

    // 弹框
    openAdd() {
      if (!this.receiveID) {
        // this.$message.warning("请选择销售组织");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }

      this.rowSelectionType = "checkbox";
      this.isVisible = true;
      if (!this.searchIpt || this.searchIpt.length < 2) {
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = 0;
        this.dataCache = []; //缓存列表数据，前端分页
        this.dataList = []
        return;
      }
      this.getData();
    },
  }
};
