<template>
  <div>
    <a-spin :spinning="pageLoadFlagaa" size="large">
      <div class="prod-list-wrap">
        <div class="title-wrap">商品信息
          <span class="tip">提示：残次品只能退单机</span>
          <a-upload
            class="btn"
            name="file"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @change="handleChange"
            :showUploadList = false
          >
            <h-btn
              class="btn"
              :width="66"
              :height="28"
              content="导入"
              level="1"
              type="primary"
              fontSize="16px"
              borderRadius="4"
          />
          </a-upload>
           <h-btn
              class="btn"
              :width="88"
              :height="28"
              content="下载模板"
              level="1"
              type="primary"
              fontSize="16px"
              borderRadius="4"
              @click="downFile"
          />
          
        </div>
        <div class="product-list-wrap">
          <div class="product-info-wrap"  v-for="(item, index) of productList" :key="index">
            <div>
              <div class="chekImgBox">
                <div
                    class="prod-img-wrap"
                >
                  <h-img2
                      class="prod-img"
                      :imgUrl="'/product/pic.nd?productId=' + item.productCode"
                      :errorImg="
                  '/assets/new/images/product/' + item.materialGroupCode + '.jpg'
                "
                      :isUseBasePath="true"
                      alt=""
                  />
                </div>
              </div>
              <div class="detail-wrap">
                <p class="name">
                  {{ item.model }}
                </p>
                <div class="colorGroup">
                  <p class="color">品牌：{{ item.brandName }}</p>
                  <p
                      class="color two"
                      style="margin-left:16px;"
                      :title="item.colour"
                  >
                    颜色：{{ item.colour }}
                  </p>
                </div>
                <div class="tiji">
                  <p class="brand">体积：{{ item.volume }}m³</p>
                </div>
              </div>
              <div class="price-wrap">
                <!-- 从这里开始 -->
                <p class="price">
                  <number-box
                    class="number-box"
                    :curVal.sync="item.ProNum"
                    :maxVal="item.bigQty"
                />
                </p>

                <div class="discutBox">
                  <p
                      class="discuinfo"
                  >
                    质量等级：{{ item.invStatusName }}
                  </p>
<!--                  <p-->
<!--                      class="discuinfo"-->
<!--                      style="margin-left:22px"-->
<!--                  >-->
<!--                    补差类型：{{ item.invStatusTypeName }}-->
<!--                  </p>-->
                </div>
              </div>
              <div class="money-wrap">
                <p>
                  退机原因：
                </p>
                <div style="display: block">
                  <a-textarea placeholder="请输入退机原因" v-model="item.message" style="width:172px;height:72px;margin-top:8px;font-size:12px" :rows="4" />
                </div>
              
              </div>
              <div class="money-wrap">
                <p>
                  机编码：（退多台请按enter换行）
                </p>
                <div style="display: block">
                  <a-textarea placeholder="请输入机编码"  v-model="item.MachineCode" style="width:188px;height:72px;margin-top:8px" :rows="4" @change="changeInput"/>
                </div>
              </div>
              <div class="close">
                <a-popconfirm
                    placement="topRight"
                    title="确认删除该商品？"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="delProduct(index)"
                >
                  <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
                </a-popconfirm>
              </div>
            </div>
          </div>


        </div>
        <div  v-if="showUpload" class="upload">
          <a-upload
            class="btn"
            name="file"
            :file-list="fileList1"
            :before-upload="beforeUpload1"
            @change="handleChange1"
          >
            <a-button > <a-icon type="upload" /> 上传附件 </a-button>
          </a-upload>
        </div>
        <div class="modelClick" @click="openAdd">+新增商品</div>
      </div>
      <div class="sum-wrap-wrap">
        <div class="sum-wrap">
          <p class="info">
            共 {{ $util.getFloat(getTotalProd(),2 )}} 件商品 体积合计{{
              $util.getFloat(getTotalVol(), 3)
            }}m³
            <a-popover placement="topRight">
              <template slot="content">
                <p>起运量标准：单电视商家5台，白电及全品类商家3m³</p>
              </template>
              <template slot="title">
                <span>物流费用规则</span>
              </template>
              <i class="iconfont icon-tijiwenti-pc problemfang"></i>
            </a-popover>
          </p>
        
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>


export default {
  name: "ProductList",
  props: {
    productList: {
      type: Array,
      required: true
    },
    showUpload:{
       type: Boolean,
        required: true
    },
     pageLoadFlagaa:{
        type: Boolean,
        required: true
    },
    
  },
  data() {
    return {
      fileList: [],
      fileList1:[],
    
    };
  },
  methods: {
    // 下载模板
    downFile() {
      this.$emit('downFile')
    },
    // 导入商品信息
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
      this.fileList = this.fileList.slice(-1);
      if( this.fileList.length > 0) {
        this.$emit("importFile", this.fileList);
      }
    },

    // 上传附件
    beforeUpload1(file) {
      this.fileList1 = [...this.fileList1, file];
      return false;
    },
    handleChange1({ fileList }) {
      this.fileList1 = fileList;
      this.fileList1 = this.fileList1.slice(-1);
      this.$emit("fileUpload", this.fileList1 );
    },
    // 根据输入的机编码个数更改数量
    changeInput(e){
        this.productList.forEach(item=>{
          if(e.target.value == item.MachineCode) {
              item.ProNum = ((item.MachineCode.slice(0,item.MachineCode.length-1)).split('\n')).length
          }
        })
    },
    //编辑
    edit(item) {
      this.$emit("edit", item);
    },
   
    // 选中商品
    itemClick(item, e) {
      item.checked = e.target.checked;
      this.$forceUpdate();
    },
    //删除商品
    delProduct(index) {
      this.productList.splice(index, 1);
    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (Number(item.ProNum )|| 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.ProNum || 0) * (item.volume || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        if (item.checked && !isNaN(item.bprice)) {
          return sum + (item.ProNum || 0) * (item.bprice || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getxj(bprice, borderedQty, item) {
      item.amount = new Number((bprice || 0) * (borderedQty || 0)).toFixed(2);
      return item.amount;
    },
    openAdd() {
      this.$emit("openAdd");
    }
  }
};
</script>

<style lang="less" scoped>
.prod-list-wrap {
  width: 1188px;
  margin: 16px auto;
  margin-bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  box-sizing: border-box;

  .title-wrap {
    line-height: 22px;
    padding-top: 24px;
    padding-left: 24px;
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    .tip{
      height: 17px;
      font-size: 12px;
      color: #FF8F3C;
      line-height: 17px;
      margin-left: 24px;
    }
    .btn {
      float:right;
      margin-right: 16px;
    }
    .btn .btn:last-child {
      float:right;
      margin-right: 8px;
    }
  }

  .product-list-wrap {
    width: 1140px;
    margin: 0 auto;

    div {
      display: inline-block;
    }
    //
    //.commodity-item {
    //  width: 1140px;
    //  height: 144px;
    //  border: 1px solid #eeeeee;
    //  margin-top: 16px;
    //  padding: 22px 24px;
    //  box-sizing: border-box;
    //  display: flex;
    //  justify-content: flex-start;
    //  // align-items: center;
    //}
    //
    //.commodity-item-check {
    //  width: 30px;
    //  min-height:20px;
    //  margin-right: 24px;
    //  line-height: 98px;
    //
    //  /deep/ .ant-checkbox-input {
    //    width: 20px;
    //    height: 20px;
    //  }
    //}
    //
    //.commodity-item-detail {
    //  //margin-right: 60px;
    //  width: 300px;
    //
    //  .pro-img {
    //    width: 100px;
    //    height: 100px;
    //    margin-right: 16px;
    //    float: left;
    //  }
    //
    //  .commodity-item-title {
    //    float: left;
    //    text-align: left;
    //
    //    p {
    //      font-size: 14px;
    //      font-family: PingFangSC-Medium, PingFang SC;
    //      font-weight: 500;
    //      color: #262626;
    //      margin-top: 15px;
    //      margin-bottom: 14px;
    //    }
    //
    //    span {
    //      display: inline-block;
    //      margin-top: 5px;
    //      font-size: 12px;
    //      font-family: PingFangSC-Regular, PingFang SC;
    //      font-weight: 400;
    //      color: #aaaaaa;
    //
    //      .itemOne {
    //        margin-right: 10px;
    //      }
    //    }
    //  }
    //}
    //
    //.commdity-item-jiage {
    //  width: 210px;
    //  margin-top: 15px;
    //  margin-right: 10px;
    //
    //  .size {
    //    float: left;
    //    margin-bottom: 11px;
    //    display: inline-block;
    //    width: 100%;
    //    text-align: left;
    //
    //    .ant-input-number {
    //      display: inline-block;
    //      width: 80px;
    //      height: 28px;
    //      border-radius: 4px;
    //      border: 1px solid #cccccc;
    //      text-align: center;
    //      line-height: 28px;
    //      margin-right: 5px;
    //    }
    //
    //    /deep/ .ant-input:focus {
    //      border-color: rgba(0, 170, 166, 1) !important;
    //    }
    //  }
    //
    //  .sizeLevel {
    //    font-size: 12px;
    //    font-family: PingFangSC-Regular, PingFang SC;
    //    font-weight: 400;
    //    color: #aaaaaa;
    //    text-align: left;
    //    display: block;
    //    width: 100%;
    //
    //    span {
    //      margin-right: 10px;
    //    }
    //  }
    //}
    //
    //.commdity-item-num {
    //  margin-top: 10px;
    //  width: 250px;
    //
    //  .number-box {
    //    height: 38px;
    //    float: left;
    //    margin-bottom: 6px;
    //    display: block;
    //    width: 100%;
    //    text-align: left;
    //
    //    // margin-bottom: 11px;
    //    /deep/ .btn-number {
    //      line-height: 40px;
    //    }
    //  }
    //
    //  .sizeLevel {
    //    span {
    //      margin-right: 10px;
    //    }
    //  }
    //}
    //
    //.commdity-item-total {
    //  text-align: left;
    //  // margin-right: 39px;
    //
    //  p {
    //    font-size: 14px;
    //    font-family: PingFangSC-Medium, PingFang SC;
    //    font-weight: 500;
    //    color: #262626;
    //    margin-bottom: 5px;
    //  }
    //
    //  span {
    //    display: inline-block;
    //    font-size: 12px;
    //    font-family: PingFangSC-Medium, PingFang SC;
    //    font-weight: 500;
    //    color: #777777;
    //    margin-top: 5px;
    //    cursor: pointer;
    //  }
    //}
    //
    //.close {
    //  // margin-top: 30px;
    //  margin-left: 10px;
    //  width: 20px;
    //  height: 20px;
    //  border-radius: 50%;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  flex-direction: column;
    //  color: #aaaaaa;
    //  // background-color: rgb(110, 112, 112);
    //  cursor: pointer;
    //
    //  i {
    //    font-size: 20px;
    //  }
    //}
    //
    //.close:hover {
    //  // background-color: #00AAA6;
    //  background-image: url(../../../assets/cart/close.png);
    //  color: #fff;
    //  background-size: 100%;
    //}
    .product-info-wrap {
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      // padding-bottom: 8px;
      margin-top: 16px;
      div{
        background-color: #fff;
        padding-bottom: 7px;
        // min-height: 144px;

        // margin-top: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .chekImgBox {
          display: flex;
          flex-direction:row;
          align-items: center;
          justify-content: center;
          margin-left: 24px;
          width: 126px;
          margin-top: 22px;
          .check-prod-wrap {
            width: 20px;
            height: 20px;
          }
          .prod-img-wrap {
            margin-left: 24px;
            width: 100px;
            height: 100px;
            cursor: pointer;
            .prod-img {
              width: 100px;
              height: 100px;
            }
          }
        }
        .detail-wrap {
          margin-left: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-top: 38px;
          min-width: 230px;
          .name {
            font-size: 14px;
            font-weight: 500;
            color: #262626;
          }
          .colorGroup {
            display:flex;
            align-items:center;
            min-width: 220px;
            margin-top: 16px;
            .color{
              // padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
            }
            .two {
              cursor: pointer;
              text-align: left;
              max-width: 130px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow:ellipsis;
            }
          }
          .hButton {
            padding-top: 8px;
          }
          .tiji {
            display: flex;
            text-align: flex-start;
            margin-top: -8px;
            .brand {
              padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
            }
            .info {
              padding-left: 16px;
              padding-top: 8px;
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
            }
          }

        }
        .price-wrap {
          margin-left: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-top: 30px;
          // padding-left: 60px;
          min-width: 210px;
          .price {
            .new-price{
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              .ant-input-number {
                display: inline-block;
                width: 80px;
                height: 28px;
                border-radius: 4px;
                border: 1px solid #cccccc;
                text-align: center;
                line-height: 28px;
                margin-right: 5px;
              }
              /deep/ .ant-input:focus {
                border-color: rgba(0, 170, 166, 1) !important;
              }
            }
            .old-price {
              line-height: 20px;
              font-size: 14px;
              color: #AAAAAA;
              text-decoration: line-through;
              margin-left: 10px;
            }
          }

          .discutBox{
            display:flex;
            align-items:flex-start;
            // padding-top: 8px;
            min-width: 210px;
            .discuinfo {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
            }
          }
          .info {
            padding-top: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
          }
        }
        .count-wrap {
          width: 220px;
          margin-top: 0px;
          padding-left: 24px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .number-box {
            margin-top: 22px;
          }
          .info {
            // padding-top: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
            text-align: left;
            // min-width: 77px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

          }
          .share{
            padding-left: 16px;
          }

        }
        .money-wrap {
          margin-left: 24px;
          margin-top: 38px;
         
          display: flex;
          flex-direction: column;
          align-items: flex-start;colorGroup
          .operate-wrap {
            margin-top: 44px;
            .zehngce {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 16px;
            }
            .colloe {
              cursor: pointer;
              font-size: 14px;
              span {
                font-size: 14px;
                color: #1890FF!important;
              }
              .icon-shouqi {
                font-size: 12px;
                transform: scale(0.7);
                margin-left: 2px;
              }
            }
          }
          .sum-wrap {
            .sum {
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
          }
        }
        .close {
          margin-top: 38px;
          margin-left: 45px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #AAAAAA;
          // background-color: rgb(110, 112, 112);
          cursor: pointer;
          .iconfontgwc {
            margin-top: -6px;
            // background-color: aquamarine;
            font-size: 20px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

        }
        .iconfontgwc:hover {
          margin-top: -6px;
          // background-color: #00AAA6;
          // margin-top: -4px;
          background-image: url(../../../../assets/cart/close.png);
          color: #fff;
          background-size: 100%;

        }
      }
      .choosePlice {
        margin-top: -26px;
        padding-left: 184px;
        padding-top: 8px;
        padding-bottom: 20px;
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        .zehngce {
          color: #fff;
          font-size: 12px;
          display:block;
          width: 106px;
          height: 18px;
          // background: #FD6A6E;
          background-image: url('../../../../assets/cart/police.png');
          background-size: 100%;
        }
        .zc-val-item {
          padding-left: 24px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          .chooseItem {
            font-size:12px;
            color:#262626!important;
            white-space: nowrap;
            overflow: hidden;
          }
          .namepliceBox {
            text-align: left;
            min-width: 90px;
            height: 20px;
            border-radius: 2px;
            // display: flex;
            // align-items: center;
            line-height: 20px;
            justify-content: flex-start;
            background-color: #f2f3f5!important;
            font-size: 12px;

            .nameplice {
              display: flex;
              align-items: center;
              padding-left: 6px;
              color: #262626!important;

            }
            .icon-guanbi-gouwuche {
              padding-left: 8px;
              padding-right: 4px;
              font-size: 16px;
              // margin-top: -2px;
              color: #AAAAAA;
            }
          }
        }

      }

    }

  }
  .upload {
    margin-top:12px;
    display:flex;
    align-items:flex-start;
    margin-left: 24px;
    /deep/.ant-upload-list-item-card-actions {
      position: absolute;
      right: -19px
    }
    .btn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .modelClick {
    width: 460px;
    height: 38px;
    background: #ffffff;
    border: 1px dashed #cccccc;
    margin: 16px auto;
    margin-bottom: 0;
    line-height: 38px;
    font-size: 14px;
    font-weight: 400;
    color: #1890FF;;
    cursor: pointer;
  }
}

.sum-wrap-wrap {
  width: 1188px;
  margin: 0 auto;
  padding: 24px 0;
  background: #fff;
  padding-top: 0;
  // margin-bottom: 16px;

  .sum-wrap {
    width: 1140px;
    height: 88px;
    background: #f7f7f7;
    margin: 0 auto;
    padding-right: 24px;
    // padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      text-align: right;
      color: #777777;
      font-size: 12px;
      line-height: 24px;

      span {
        color: #262626;
      }

      .problemfang {
        // margin-left: 6px;
        color: #0caeaa;
        cursor: pointer;
      }

      .yufu {
        margin-right: 80px;
      }
    }
  }
}
</style>
