import { render, staticRenderFns } from "./ProductModal.vue?vue&type=template&id=510e3eae&scoped=true"
import script from "./ProductModal.vue?vue&type=script&lang=js"
export * from "./ProductModal.vue?vue&type=script&lang=js"
import style0 from "./ProductModal.vue?vue&type=style&index=0&id=510e3eae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510e3eae",
  null
  
)

export default component.exports